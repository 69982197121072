@font-face {
  font-family: "OpenSans-Regular";
  src: url("./assets/fonts/OpenSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
.MuiTypography-h5.loginHeading {
  font-family: OpenSans-Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 30px;
}
.align-chat-button {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  bottom: 0;
}
.shared-profile {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.09);
  border: 1px solid #ffffff;
}
.msg-type {
  padding: 15px !important;
  border-radius: 25px;
  background: lightgrey;
  outline: none;
  width: 100%;
  min-height: 40px;
  border: none;
}
.msgSend button {
  border-radius: 50%;
  border: none;
  padding: 11px;
  margin-left: 5px;
  background: #fec803;
}
.MuiIconButton-root:hover {
  background-color: transparent !important;
}
.pd-l-15 {
  padding-left: 15px;
}
.pd-0 {
  padding: 0px !important;
}
.modal-header {
  text-align: center;
  background-color: #f5faff;
}
.modal-header h2 {
  padding: 17px;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #005a98;
}
.modal-header.dialogTitleFilter h2 {
  color: #000;
}
.dialog-main-div .MuiDialog-container .MuiDialog-paper {
  min-width: 585px;
  min-height: 507px;
}

.pd-t-b-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.mainContent {
  background-color: #e5e5e5;
}
.MuiInput-underline:after {
  border-bottom: none !important;
}
.MuiNativeSelect-select:focus {
  border-radius: 7px !important;
}
.MuiNativeSelect-select::after {
  margin: 40px;
  background: #ffc805;
  color: #333333;
  border: 1px solid red;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
option {
  min-height: 5em;
}
.MuiInput-underline:before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}
.uploadDocDiv {
  height: 414px;
  margin: 40px 165px 0px 75px;
  display: flex;
  justify-content: center;
}
.upload-alignment {
  margin: 40px 155px 0px 0px !important;
}
.shared-details {
  min-height: 380px;
  height: auto;
  display: flex;
  justify-content: center;
}
.shared-details-color {
  background: rgba(245, 250, 255, 1);
}
.view-details-color {
  background: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 90, 152, 0.07);
}
.img-side {
  margin: 40px 20px 20px 20px;
  height: 260px;
  width: 277px;
  background: #ffffff;
  box-shadow: 0px 7px 24px rgba(0, 90, 152, 0.07);
  border-radius: 10px;
}
.calender {
  position: relative;
  bottom: 14px;
}
.img-side-profile {
  display: block;
  margin: auto;
  transform: translate(0%, 10%);
  height: 280px;
  width: 280px;
  background: #ffffff;
  box-shadow: 0px 7px 24px rgba(0, 90, 152, 0.07);
  border-radius: 50%;
}
.text-title {
  font-family: OpenSans-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #333333;
  margin-left: 4px;
}

.MuiSelect-nativeInput {
  color: red !important;
}
.label-heading {
  font-family: OpenSans-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.label-data {
  font-family: OpenSans-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 2;
  /* word-break: break-all; */
}
.bubble:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: transparent rgba(245, 250, 255, 1);
  border-width: 8px 8px 8px 0;
  top: 59%;
  left: 60px;
  margin-top: -20px;
}
.uploadDocColor {
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(22, 101, 216, 0.05);
  border-radius: 5px;
}
.align-progress {
  top: 28px;
  height: 4px;
  width: 528px;
}
.align-doc-icon {
  top: 18px;
  left: 17px;
  position: relative;
}
.uploadProgress {
  height: 70px;
  width: 100%;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(22, 101, 216, 0.07);
}
.align-add {
  float: right;
  position: relative;
  top: -35px;
  right: 221px;
}
.room_room__yiKdN {
  overflow: hidden;
}
.link-text {
  font-size: 20px;
  color: #ffcf5c;
  font-weight: bold;
  text-decoration: underline;
  font-family: OpenSans-Regular;
  text-transform: capitalize;
}
.ruler-filter {
  width: 396px;
  background-color: #cbe4fd !important;
}
.ruler-multiple {
  margin-top: 30px !important;
  width: 799px;
  background-color: #757575 !important;
  opacity: 0.2;
}
.display-center {
  display: flex !important;
  justify-content: center !important;
}
.mr-t-50 {
  margin-top: 50px !important;
}
.mr-t-10 {
  margin-top: 10px !important;
}
textarea {
  resize: none;
}
.submit-btn.btn-shared,
.shared-btn-rate.btn-shared {
  height: 60px;
  width: 250px;
  border-radius: 10px;
}

.shared-btn-rate.btn-shared {
  border: 1px solid #005a98;
}
.shared-btn-rate.btn-shared,
.submit-btn.btn-shared {
  font-family: OpenSans-Regular;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #005a98;
  text-transform: capitalize;
}
.BlockchainHeading {
  color: #005a98;
}

.uploadDoc {
  text-align: center;
  width: 654px;
  height: 235px;
  background: rgba(255, 207, 92, 0.06);
  border: 1px dashed rgba(255, 200, 5, 0.6);
  box-sizing: border-box;
  margin-top: 40px;
}
.pd-l-r-16 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}
.top-20 {
  top: 20px;
}
.align-icon {
  position: relative;
  top: 4px;
}
.rememberMe .MuiCheckbox-colorPrimary.Mui-checked,
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #ffcf5c;
}
.percentage-color {
  color: #979797;
}
.mr-r-5 {
  margin-right: 5px;
}
.doc-title {
  position: relative;
  top: 15px;
}

.MuiPaper-root.float-right.grid-display,
.grid-display.date-value {
  box-shadow: none;
  background: transparent;
}
.mr-r-20 {
  margin-right: 20px;
}
.mr-r-16 {
  margin-right: 16px;
}
.float-right {
  float: right;
}
.MuiButtonBase-root.detail-btn {
  padding: 16px 0px 16px 0px;
  background: #ffc805 !important;
  margin-right: 20px;
  border-radius: 10px;
}
.room_remoteParticipants__3fSFA {
  overflow: auto !important;
}
.DateRangePicker_picker {
  z-index: 999 !important;
}
.DateRangePicker {
  bottom: 5px;
}
.MuiListItem-root,
.sideNav .MuiSvgIcon-root {
  color: #005a98;
  font-family: OpenSans-Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.MuiDrawer-root {
  overflow-y: hidden;
  overflow-x: hidden;
}
.padding-right-0 {
  padding-right: 0;
}
.padding-right-25 {
  padding-right: 25px !important;
}
.MuiTypography-body2.ListHeading,
.ListHeading {
  font-family: OpenSans-Regular;
  font-size: 18px;
  font-weight: 600;
  color: #005a98;
}
.MuiTypography-body2.ListHeadingMultiple,
.ListHeadingMultiple {
  font-family: OpenSans-Regular;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}
.listName {
  font-family: OpenSans-Regular;
  font-size: 14px;
  font-weight: 600;
  color: #005a98;
}
.margin-value.mr-l-10 {
  margin-left: 10px;
}
.mr-l-30,
.margin-value.mr-l-30 {
  margin-left: 30px;
}
.MuiTypography-body2.listHeadingStar,
.listHeadingStar {
  font-size: 15px;
  font-family: OpenSans-Regular;
  font-weight: 600;
  color: #005a98;
}
.border-none .MuiPaper-root {
  border: 0;
}
.MuiToolbar-root.height-125,
.MuiPaper-root .makeStyles-toolbar-9,
.sideNav-main.logo-main,
.MuiPaper-root .makeStyles-toolbar-11 {
  min-height: 125px;
  background: #ebf5ff;
  /* color: #005a98; */
}
.sideNav-main.logo-main {
  display: flex;
  padding: 0px 8px;
  /* min-height: 56px; */
  align-items: center;
  justify-content: flex-end;
}
.arrowBox {
  padding: 10px;
  height: 43px;
  width: 46px;
  left: 270px;
  top: 41px;
  border-radius: 5px;
  background: #ffcf5c;
}
.MuiTypography-root.headingData {
  font-family: OpenSans-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  color: #005a98;
}
.MuiTypography-root.loginPara {
  font-family: OpenSans-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 32px;
}
.sideNav.MuiList-root {
  padding-left: 36px;
}
.account {
  color: #000000;
}
.heading1 {
  font-size: 16px;
  font-weight: 700;
  color: #005a98;
}
.heading3 {
  font-size: 16px;
  font-weight: 700;
  color: #ffc805;
}
.heading2 {
  font-size: 14px;
  font-weight: 400;
  color: #005a98;
}
.btn-doc {
  padding: 20px 60px;
  background: #ffc805;
  border-radius: 6px;
  border: none;
  line-height: 1;
}
.videoModal .MuiDialog-container .MuiDialog-paper {
  overflow: hidden;
}
.btn-doc-close {
  padding: 16px 60px;
  border: 2px solid #ffc805;
  border-radius: 6px;
  background-color: #ffffff;
}
.btn-doc:focus {
  outline: none;
}
.btn-doc-close:focus {
  outline: none;
}
.btn-doc-close.upload-btn {
  padding: 21px 60px;
}
.name-lable {
  margin-left: 14px;
  color: #000000;
  font-size: 20px;
  left: 0px;
  position: relative;
  top: 5px;
  background: #ebf5ff;
}
.rating-align {
  top: 3px;
  right: 21px;
}
.mr-b-50 {
  margin-bottom: 50px;
}
.mr-b-25 {
  margin-bottom: 25px !important;
}
.align-loader {
  display: grid;
  justify-content: center;
}
.MuiCircularProgress-colorPrimary {
  color: #ffc805 !important;
}
.MuiDivider-root.ruler {
  margin: 0px 36px;
  background-color: #005a98;
}
@media (min-width: 600px) {
  .position-sidenav.sidenav-width {
    width: 150px;
  }

  .MuiPaper-root.MuiDrawer-paper {
    background: #f9fcff;
    border-right: 0;
  }
  .notification {
    color: #231f20;
  }
  .notifi-btn {
    position: absolute !important;
    top: 23px;
  }
}
.main-switch.MuiSwitch-root {
  width: 60px;
  height: 28px;
  padding: 0;
  margin-top: 15px;
}
.main-switch.mr-t-0.MuiSwitch-root {
  margin-top: 0px;
}

.main-switch.add-user-switch.MuiSwitch-root {
  margin-top: 8px;
  margin-bottom: 20px;
}
.MuiSwitch-switchBase {
  top: 0px !important;
  left: 0px !important;
  color: #ffff !important;
}
.PrivateSwitchBase-root-33 {
  padding: 10px !important;
}
.MuiSwitch-thumb {
  width: 24px !important;
  height: 24px !important;
  box-shadow: none !important;
  border-radius: 50% !important;
}
.main-switch .MuiSwitch-track {
  border-radius: 30px !important;
  background: #e1e1e1 !important;
  opacity: 1 !important;
}
.seeAll-text {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #ffc805 !important;
  opacity: 1 !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
  color: #ffffff !important;
}
.MuiSwitch-switchBase.MuiButtonBase-root.MuiIconButton-root {
  padding: 1px 0px 0px 4px !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
  padding: 1px 0px 0px 8px !important;
}
.MuiSwitch-switchBase.Mui-checked {
  transform: translateX(33px) !important;
  padding: 4px 0px 0px 10px;
}
.margin-value.mr-t-10 {
  margin-top: 4rem;
}
.margin-value.mr-l-4 {
  margin-left: 10rem;
}
.main-btn.submit-btn {
  background: #ffc805 !important;
  color: #005a98;
  font-family: OpenSans-Regular;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
}
.auto-check-user .MuiTextField-root .MuiAutocomplete-inputRoot .MuiChip-root {
  background: RGBA(255, 200, 5, 0.6);
  font-family: OpenSans-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #005a98;
}
.align-count {
  position: relative;
  bottom: 27px;
  right: 10px;
}
.dropDown-font,
#checkboxes-tags-demo::placeholder {
  font-family: OpenSans-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #333333 !important;
}
.display-block-center {
  display: block;
  margin: auto !important;
}
.border-radius-10 {
  border-radius: 10px !important;
}
.MuiChip-deleteIcon {
  color: #005a98 !important;
}
.MuiAutocomplete-listbox {
  border: 1px solid #ffc805;
}
.Mui-checked {
  color: #ffc805 !important;
  border: 1px solid #005a98;
}
.MuiRadio-root {
  color: #ffc805 !important;
}
.width-100 {
  width: 100% !important;
}
.padding-right-100 {
  padding-right: 100px !important;
}
.MuiDropzonePreviewList-imageContainer .MuiDropzonePreviewList-image {
  color: #005a98;
  width: initial;
  height: 25px;
  position: relative;
  top: 4px;
  box-shadow: none;
  right: 12px;
}
.icon-active-alignment {
  position: relative;
  top: 2px;
  font-size: 12px;
  right: 19px;
  font-size: 12px;
}
.position-calender {
  position: relative;
  left: 14px;
  bottom: 2px;
}
.MuiDropzonePreviewList-imageContainer .MuiDropzonePreviewList-removeButton {
  color: #005a98 !important;
  top: -3px !important;
  right: -19px !important;
  position: relative !important;
  background: transparent;
  box-shadow: none;
  opacity: 1;
}
.MuiDropzonePreviewList-imageContainer .MuiTypography-root {
  display: inline-block;
  font-size: 21px;
  font-family: OpenSans-Regular;
  font-size: 18px;
  font-weight: 600;
  color: #005a98;
}
.dialog-content.pd-70 {
  padding: 70px;
}
.dialog-content.pd-filter {
  padding: 35px 35px 25px 40px;
}

.dialog-content.pd-30 {
  padding: 30px;
}
.mr-b-40 {
  margin-bottom: 40px !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
  padding: 2px 0px 0px 0px !important;
}
.MuiSwitch-switchBase.MuiButtonBase-root.MuiIconButton-root {
  padding: 2px 0px 0px 4px !important;
}
.label-font.input-label {
  color: #333333;
  font-family: OpenSans-Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.history-text {
  font-family: OpenSans-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.align-item-end {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.align-item-center {
  justify-content: center;
  display: flex;
  align-items: center;
}
.revision-request-color {
  color: rgb(255, 139, 123);
}
.pending-request-color {
  color: rgb(255, 85, 81);
}
.approved-color {
  color: rgb(0, 194, 19) !important;
}
.arrow-position {
  position: relative;
  top: 5px;
  right: 4px;
}
.uploadHeading1 {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.font-open-sans {
  font-family: OpenSans-Regular !important;
  font-style: normal !important;
}
.report-modal,
.report-modal.chatHeading {
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 90, 152, 1);
}
.radio-option {
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 90, 152, 1);
}
.linkHead.head1 {
  font-size: 45px;
  font-weight: 700;
  margin-top: 50px;
}
.linkHead.head2 {
  font-size: 25px;
  font-weight: 400;
  margin-top: 20px;
}
.upload-modal-heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  color: #005a98;
}
.upload-modal-heading-2 {
  font-size: 25px;
  font-weight: 400;
  color: #333333;
}

.head-title {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.head-title-color {
  color: #333333 !important;
  font-family: OpenSans-Regular !important;
  font-size: 15px !important;
}
.version-font {
  font-family: OpenSans-Regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.uploadHeading2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #000000;
}
.history-sub-text {
  font-family: OpenSans-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
}

input[type="text"],
input[type="number"],
select.MuiSelect-select,
.MuiNativeSelect-root.MuiNativeSelect-select {
  padding-left: 12px;
}
.send-background {
  background: rgba(250, 250, 250, 1);
}
textarea {
  padding-left: 12px !important;
  padding-top: 16px !important ;
}
.background-blue {
  background: rgba(245, 250, 255, 1) !important;
}
.icon-edit {
  border-radius: 50%;
  font-size: 35px !important;
  padding: 5px;
  fill: #005a98 !important;
  border: 2px solid #005a98;
  background: #ffcf5c;
}
body {
  background-color: #ffffff !important;
}
.pd-t-40 {
  padding-top: 40px !important;
}
.pd-t-25 {
  padding-top: 25px !important;
}
.pd-t-15 {
  padding-top: 15px !important;
}
input[type="file"] {
  cursor: pointer;
  width: 200px;
  margin-top: 9px;
  height: 44px;
  overflow: hidden;
}
input[type="file"].upload-multiple-doc {
  cursor: pointer;
  width: 77px;
  margin-top: 0px;
  height: 44px;
  overflow: hidden;
}
input[type="file" i]:focus {
  outline: none;
}

input[type="file"]:before {
  width: inherit;
  height: inherit;
  font-size: 16px;
  line-height: 42px;
  content: "Select Your Image";
  display: inline-block;
  background: rgba(255, 207, 92, 0.06);
  border: 1px dashed rgba(255, 200, 5, 0.6);
  padding: 0 10px;
  text-align: center;
  color: #005a98;
  font-weight: 700;
}

input[type="file"].upload-multiple-doc:before {
  width: iherit;
  height: inherit;
  font-size: 20px;
  line-height: 42px;
  content: "Upload";
  display: inline-block;
  background: none;
  border: none;
  padding: 0px;
  text-align: center;
  text-decoration: underline;
  color: #ffcf5c;
  font-weight: 700;
}
.text-image.image-name {
  margin-top: 30px;
  color: #005a98;
  font-weight: 700;
  font-size: 16px;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
.nav-list-text {
  font-family: OpenSans-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #005a98 !important;
}
.add-active {
  color: #ffc805;
}
.font-size-12 {
  font-size: 12px !important;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: transparent !important;
  color: #ffc805 !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.cursor-pointer {
  cursor: pointer;
}
.MuiDropzoneArea-icon {
  width: 147px !important;
  height: 166px !important;
  color: #ffc805 !important;
}
.MuiDropzoneArea-icon {
  width: 147px !important;
  height: 166px !important;
  color: #ffc805 !important;
}

.MuiDropzoneArea-root {
  background: rgba(255, 207, 92, 0.06) !important;
  border: 1px dashed rgba(255, 200, 5, 0.6) !important;
  outline: none;
}
.MuiDropzoneArea-text {
  margin-top: 30px !important;
}
.dropzone-modal
  .MuiDropzoneArea-root
  .MuiDropzoneArea-textContainer
  .MuiDropzoneArea-icon {
  height: 80px !important;
  width: 80px !important;
}
.MuiListItem-button:hover {
  text-decoration: none;
  background-color: transparent !important;
}
.upload-submit {
  width: 200px;
  height: 64px;
}
.upload-alignment {
  padding: 17px 30px;
}
.MuiTableSortLabel-icon {
  opacity: 1;
}
.tooltip:after {
  content: "";
  position: absolute;

  /* position tooltip correctly */
  left: 70%;
  margin-left: -5px;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* the arrow */
  border: 10px solid #000;
  border-color: transparent black transparent transparent;
}
.headig-option {
  font-family: OpenSans-Regular;
  font-style: normal;
  font-weight: normal;
  color: #333333;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-8 {
  font-size: 8px;
}
.btn-close:hover {
  background: none;
}
.btn-close {
  color: #005a98 !important;
}
.rotateUp {
  transform: rotate(-180deg);
}
.rotateDown {
  transform: rotate(0);
}
#categoryName::placeholder,
#description::placeholder,
input[type="text"]::placeholder,
input[type="number"]::placeholder {
  color: #212529;
  font-family: OpenSans-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.2;
}
.align-rating {
  bottom: 3px;
  left: 10px;
}
.pd-r-20 {
  padding-right: 20px !important;
}
.MuiDropzoneArea-text {
  font-family: OpenSans-Regular;
  font-size: 18px;
  font-weight: 600;
  color: #005a98;
}
.display-inline {
  display: inline-block !important;
}

.mr-t-b-40 {
  margin: 40px 0px 40px 0px !important;
}
.mr-t-30 {
  margin-top: 30px;
}
.filter-text.head-filter {
  font-family: OpenSans-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: #333333;
}
.filter-text.subhead-filter {
  font-family: OpenSans-Regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}
.dialog-main-div-filter .MuiDialog-container .MuiDialog-paper {
  min-height: 520px;
  min-width: 84%;
}
.DateInput_input.DateInput_input_1 {
  font-family: OpenSans-Regular;
  font-size: 14px;
  font-weight: 400;
  color: #005a98;
}
#startDate {
  padding: 7px 0px 4px 16px;
  width: 110px;
}
#endDate {
  padding: 6px 5px 5px 5px;
}
.DateRangePickerInput_arrow_svg {
  margin: 0px 4px 0px 0px;
}
.DateInput.DateInput_1 {
  width: 118px;
}
.align-calender {
  top: 2px;
  position: relative;
  right: 25px;
}
.align-filter-checkbox {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.DateRangePickerInput.DateRangePickerInput__withBorder {
  border: 1px solid #005a98;
  border-radius: 4px;
}
.arrow-changepassword {
  position: relative;
  bottom: 6px;
  right: 1px;
}
.dialogTitleFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-rate-and-reviews.card-reviews {
  background: rgba(235, 245, 255, 0.3);
  box-shadow: none;
  border-radius: 20px;
}
.img-side-rate {
  margin: 8px 4px 5px 10px;
  height: 101px;
  width: 108px;
  background: #ffffff;
  box-shadow: 0px 7px 24px rgba(0, 90, 152, 0.07);
  border-radius: 10px;
}
.content-start {
  justify-content: flex-start !important;
}
.popover_class {
  margin-top: 10px;
  border: 1px solid black;
}
.popover_class::before {
  content: "";
  position: absolute;
  top: -20px;
  right: 5px;
  border-bottom: 10px solid black;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  z-index: 10;
}
.active {
  background: RGBA(255, 200, 5, 0.4);
}

/* chat module starts */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.msg-loader {
  animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite;
}
.msgSend {
  display: flex;
  justify-content: center;
  align-items: center;
}
.msgSend button {
  border-radius: 50%;
  border: none;
  padding: 11px;
  margin-left: 5px;
  background: #fec803;
}

.welcome_image img {
  width: 100%;
}
/* chat module end */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* .align-add-btn.align-btn {
    position: relative;
    bottom: 0px;
    top: 20px;
    margin-bottom: 40px !important;
    text-align: inherit !important;
  } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

