// extracted by mini-css-extract-plugin
// module.exports = {"participant":"participant_participant__zcbRR","remoteParticipant":"participant_remoteParticipant__pjE72","signal":"participant_signal__1XBfM","badNetwork":"participant_badNetwork__2_1wB","okNetwork":"participant_okNetwork__36LBR","fineNetwork":"participant_fineNetwork__2FrS9","signal1":"participant_signal1__La_SU","signal2":"participant_signal2__S_eWB","signal3":"participant_signal3__25vMy","signal4":"participant_signal4__Ebo-O","signal5":"participant_signal5__3_ylR"};

// .participant {
//     /* Your styles for the 'participant' class */
//     &__zcbRR {
//       /* Styles for the 'participant' class with modifier 'participant__zcbRR' */
//     }
//   }
  
//   .remoteParticipant {
//     /* Your styles for the 'remoteParticipant' class */
//     &__pjE72 {
//       /* Styles for the 'remoteParticipant' class with modifier 'remoteParticipant__pjE72' */
//     }
//   }
  
//   .signal {
//     /* Your styles for the 'signal' class */
//     /* Define modifiers for the 'signal' class as needed */
//     &__1XBfM {
//       /* Styles for the 'signal' class with modifier 'signal__1XBfM' */
//     }
  
//     &__S_eWB {
//       /* Styles for the 'signal' class with modifier 'signal__S_eWB' */
//     }
  
//     &__25vMy {
//       /* Styles for the 'signal' class with modifier 'signal__25vMy' */
//     }
  
//     &__Ebo-O {
//       /* Styles for the 'signal' class with modifier 'signal__Ebo-O' */
//     }
  
//     &__3_ylR {
//       /* Styles for the 'signal' class with modifier 'signal__3_ylR' */
//     }
//   }
  
//   /* Add styles for other classes and modifiers as needed */
  
.participant {
    position: relative;
    max-width: 700px;
    &.remoteParticipant {
      max-width: 150px;
      height: auto;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    h3 {
      position: absolute;
      background-color: #000000;
      opacity: 0.8;
      color: #ffffff;
      font-size: 13px;
      height: 20px;
      line-height: 20px;
      padding: 0px 10px;
      border-radius: 7px;
      top: 21px;
      right: 5px;
    }
    .signal {
      position: absolute;
      top: 10px;
      right: 8px;
      display: flex;
      transform: rotate(180deg);
      flex-direction: row-reverse;
      .badNetwork {
        background-color: red;
      }
      .okNetwork {
        background-color: orange;
      }
      .fineNetwork {
        background-color: green;
      }
      .signal1 {
        height: 3px;
        margin-right: 2px;
        display: block;
        width: 3px;
      }
      .signal2 {
        height: 7px;
        margin-right: 2px;
        display: block;
        width: 3px;
      }
      .signal3 {
        height: 11px;
        margin-right: 2px;
        display: block;
        width: 3px;
      }
      .signal4 {
        height: 15px;
        margin-right: 2px;
        display: block;
        width: 3px;
      }
      .signal5 {
        height: 19px;
        margin-right: 2px;
        display: block;
        width: 3px;
      }
    }
  }
  
  