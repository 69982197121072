// extracted by mini-css-extract-plugin
// module.exports = {"room":"room_room__3nigd","remoteParticipants":"room_remoteParticipants__18HIr","localParticipants":"room_localParticipants__3wG_3","actionContainer":"room_actionContainer__2WS-F","leaveBtn":"room_leaveBtn__3zNhu","action":"room_action__2yAJZ","muteBtn":"room_muteBtn__3KKT1","videoBtn":"room_videoBtn__UXrhJ"};

// .room {
//     /* Your styles for the 'room' class */
//     &__3nigd {
//       /* Styles for the 'room' class with modifier 'room__3nigd' */
//     }
//   }
  
//   .remoteParticipants {
//     /* Your styles for the 'remoteParticipants' class */
//     &__18HIr {
//       /* Styles for the 'remoteParticipants' class with modifier 'remoteParticipants__18HIr' */
//     }
//   }
  
//   .localParticipants {
//     /* Your styles for the 'localParticipants' class */
//     &__3wG_3 {
//       /* Styles for the 'localParticipants' class with modifier 'localParticipants__3wG_3' */
//     }
//   }
  
//   .actionContainer {
//     /* Your styles for the 'actionContainer' class */
//     &__2WS-F {
//       /* Styles for the 'actionContainer' class with modifier 'actionContainer__2WS-F' */
//     }
//   }
  
//   .leaveBtn {
//     /* Your styles for the 'leaveBtn' class */
//     &__3zNhu {
//       /* Styles for the 'leaveBtn' class with modifier 'leaveBtn__3zNhu' */
//     }
//   }
  
//   .action {
//     /* Your styles for the 'action' class */
//     &__2yAJZ {
//       /* Styles for the 'action' class with modifier 'action__2yAJZ' */
//     }
//   }
  
//   .muteBtn {
//     /* Your styles for the 'muteBtn' class */
//     &__3KKT1 {
//       /* Styles for the 'muteBtn' class with modifier 'muteBtn__3KKT1' */
//     }
//   }
  
//   .videoBtn {
//     /* Your styles for the 'videoBtn' class */
//     &__UXrhJ {
//       /* Styles for the 'videoBtn' class with modifier 'videoBtn__UXrhJ' */
//     }
//   }
  
//   /* Add styles for other classes and modifiers as needed */
  

.room {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #151a23;
  .remoteParticipants {
    margin-right: 10px;
    display: flex;
    padding: 0px 30px;
    overflow: hidden;
    overflow-x: scroll;
    padding: 20px 30px;
    margin-bottom: 20px;
    position: absolute;
    top: 10px;
    z-index: 100;
    left: 10px;

    video {
      max-width: 150px;
      height: auto;
      cursor: pointer;
      border: 2px solid #a5a4a4;
      border-radius: 5px;
    }
  }

  .localParticipants {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    padding-bottom: 20px;
    video {
      width: 100%;
      height: auto;
    }
  }

  .actionContainer {
    display: flex;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    background-color: #242424;
    padding: 0px 30px;
    flex-shrink: 0;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .leaveBtn {
      background-color: rgb(216, 3, 3);
      color: #ffffff;
      padding: 7px 13px;
      border-radius: 5px;

      border: none;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    .action {
      display: flex;
      .muteBtn {
        color: #ffffff;
        cursor: pointer;
        margin-right: 30px;
        i {
          font-size: 20px;
          color: #ffffff;
          display: block;
          text-align: center;
        }
      }
      .videoBtn {
        color: #ffffff;
        cursor: pointer;
        i {
          font-size: 20px;
          color: #ffffff;
          display: block;
          text-align: center;
        }
      }
    }
  }
}
